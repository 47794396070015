// 'use strict';

// class innerCtrl {
//   //start-non-standard
//   user = {};
//   errors = {};
//   submitted = false;
//   //end-non-standard

//   constructor(Auth, $state,$scope) {
//     this.Auth = Auth;
//     this.$state = $state;
//     console.log('detail',$state);
//     angular.element(document).ready(function () {
//       // alert($('#innerheader').outerHeight() + "px");
//      // alert($('.search_holder').outerHeight() + "px");
//     $('.search_detailsholder').css('height', $(window).height() - 46  - $('.search_block').outerHeight() + "px");
//       $('.save_searchbtn').click(function(){
//          $('.toggle_btn').css('display', 'block');
//          $('.save_searchbtn').css('display', 'none');
//       });
//        $('.t_save').click(function(){
//         $('.toggle_btn').css('display', 'none');
//       // $('.save_searchbtn').addClass('showData');
//        $('.toggle_btn').fadeOut(5000, function(){
//         $(".save_msg").fadeIn(3000).delay(1000).fadeOut(3000, function(){
//             $(".save_searchbtn").fadeIn(1000);
//           });;
//         });

//     });



//   });
//     $scope.searchItems=[{'name':'Section Name'},{'name':'Act'},{'name':'Judge Name'}];

//      $scope.hideMe = function (searchItem) {
//         searchItem.hidden=true;
//     };
//     $scope.showPan = false;
//     var counter = 1;
//     $scope.tabs = [];
//     $scope.showPanel = function(){
//       $scope.showPan = true;
//       $scope.tabs.push({ title: 'Tab ' + counter, content: 'Tab ' + counter });
//       counter++;
//       $scope.tabs[$scope.tabs.length - 1].active = true;
//     };


//     var removeTab = function (event, index) {
//       event.preventDefault();
//       event.stopPropagation();
//       $scope.tabs.splice(index, 1);
//     };

//     $scope.removeTab = removeTab;

//   }


// }

// angular.module('kljDigitalLibraryApp')
//   .controller('innerCtrl', innerCtrl);
angular.module('kljDigitalLibraryApp')
    .controller('innerCtrl', ["Auth", "$state", "$scope",
        function(Auth, $state, $scope) {
            user = {};
            errors = {};
            submitted = false;
            this.Auth = Auth;
            this.$state = $state;
            console.log('detail', $state);
            angular.element(document).ready(function() {
                // alert($('#innerheader').outerHeight() + "px");
                // alert($('.search_holder').outerHeight() + "px");
                $('.search_detailsholder').css('height', $(window).height() - 46 - $('.search_block').outerHeight() + "px");
                $('.save_searchbtn').click(function() {
                    $('.toggle_btn').css('display', 'block');
                    $('.save_searchbtn').css('display', 'none');
                });
                $('.t_save').click(function() {
                    $('.toggle_btn').css('display', 'none');
                    // $('.save_searchbtn').addClass('showData');
                    $('.toggle_btn').fadeOut(5000, function() {
                        $(".save_msg").fadeIn(3000).delay(1000).fadeOut(3000, function() {
                            $(".save_searchbtn").fadeIn(1000);
                        });;
                    });

                });



            });
            $scope.searchItems = [{ 'name': 'Section Name' }, { 'name': 'Act' }, { 'name': 'Judge Name' }];

            $scope.hideMe = function(searchItem) {
                searchItem.hidden = true;
            };
            $scope.showPan = false;
            var counter = 1;
            $scope.tabs = [];
            $scope.showPanel = function() {
                $scope.showPan = true;
                $scope.tabs.push({ title: 'Tab ' + counter, content: 'Tab ' + counter });
                counter++;
                $scope.tabs[$scope.tabs.length - 1].active = true;
            };


            var removeTab = function(event, index) {
                event.preventDefault();
                event.stopPropagation();
                $scope.tabs.splice(index, 1);
            };

            $scope.removeTab = removeTab;
        }
    ]);